import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import OfferingForm from "../components/offering/offering-form"
import RequiredOfferCards from "../components/required-offers/RequiredOfferCards"
import SectionTitle from "../components/SectionTitle"
import SEO from "../components/seo"
import { getRequiredOffers } from "../services/api"

const OfferingPageContainer = styled.div`
  margin: 0 auto;
  padding: 4.5454em 0.681em 8.1818em 0.681em;
  max-width: 760px;
`

const SubHeading = styled.p`
  text-align: center;
`

const RequiredOffersContainer = styled.div`
  margin: 80px 0;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;

  @media (min-width: 768px) {
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr;
  }
`

const OfferingsPage = ({ location }) => {
  const [requiredOffers, setRequiredOffers] = useState([])

  useEffect(() => {
    loadRequiredOffers();
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      const wow = require("wowjs")
      new wow.WOW({ live: false, mobile: false }).init()
    }
  })

  const loadRequiredOffers = async () => {
    if (location.state && location.state.requiredOffers) {
      setRequiredOffers(location.state.requiredOffers, [])
      return;
    }

    try {
      const result = await getRequiredOffers()
      setRequiredOffers(result.data, [])
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Layout>
      <SEO title="Felajánlás" />
      <OfferingPageContainer>
        <SectionTitle>Felajánlás</SectionTitle>
        <SubHeading className="wow fadeIn">
          Te is szeretnéd segíteni Hőseinket? Ha a válasz igen, akkor nagyon jó
          helyen jársz! Szeretnénk elérni, hogy az egészségügyi dolgozók minden
          támogatást megkapjanak.
        </SubHeading>
        <SubHeading className="wow fadeIn" data-wow-delay=".2s" style={{ marginBottom: '3em' }}>
          Segíthetsz ételadagok (10-15 adag), alapanyag
          felajánlással. Utóbbiból a partner éttermek főzni tudnak Hőseinknek.
          (Házi kosztot sajnos nem tudunk elfogadni.) Szomjuk oltásához műanyag
          flakonos ásványvizet várnak. Védőöltözék gyanánt a felajánlás lehet
          plexi arcvédő maszk, védőszemüveg, szájmaszk, póló, zokni, stb.
        </SubHeading>
        <OfferingForm />
        { requiredOffers.length > 0 && <RequiredOffersContainer>
          <SectionTitle>Felajánlási kérelmek</SectionTitle>
          <Grid>
            <RequiredOfferCards elements={requiredOffers} showLinks={false} />
          </Grid>
        </RequiredOffersContainer>}
      </OfferingPageContainer>
    </Layout>
  )
}

export default OfferingsPage
