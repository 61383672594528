import styled from "styled-components"
import { ERROR_COLOR, SECONDARY_COLOR } from "../variables"

const SelectInput = styled.select`
  height: 2.2727em;
  padding-left: 0.4545rem;
  background: #fff;
  border: 2px solid ${SECONDARY_COLOR};
  border-radius: 0px;
  font-size: 18px;

  &.error {
    border: 2px solid ${ERROR_COLOR};
  }

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image:
    linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%),
    linear-gradient(to right, ${SECONDARY_COLOR}, ${SECONDARY_COLOR});
  background-position:
    calc(100% - 22.5px) 1em,
    calc(100% - 17.5px) 1em,
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;

  :focus {
    background-image:
      linear-gradient(45deg, white 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, white 50%),
      linear-gradient(to right, ${SECONDARY_COLOR}, ${SECONDARY_COLOR});
    background-position:
      calc(100% - 17.5px) 1em,
      calc(100% - 22.5px) 1em,
      100% 0;
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;
    outline: 0;
  }
`

export default SelectInput;
