import React from 'react'
import styled from "styled-components"
import { ERROR_COLOR, SECONDARY_COLOR } from "../variables"

const Input = styled.textarea`
  padding: 0.4545rem;
  border: 2px solid ${SECONDARY_COLOR};
  max-width: 730px;
  min-height: 210px;
  font-size: 18px;

  &.error {
    border: 2px solid ${ERROR_COLOR};
  }
`

const Error = styled.span`
  color: red;
  font-size: 0.7272em;
`

const TextAreaInput = ({ error, ...rest }) => (
  <div>
    <Input className={error && "error"} {...rest} />
    {error && <Error>{error}</Error>}
  </div>
)

export default TextAreaInput;
